import AuthManager from "api/auth/manager";
import { AxiosError } from "axios";
import Alert from "components/display/alert";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { FC, ReactNode, useState } from "react";
import { SWRConfig } from "swr";

type Props = {
  children?: ReactNode;
};

const GlobalLayout: FC<Props> = ({ children }) => {
  const [alert, setAlert] = useState<string | null>(null);
  const router = useRouter();

  return (
    <>
      <Alert
        className="fixed mx-auto inset-x-0 w-3/4 md:w-2/4 cursor-pointer"
        visible={alert != null}
        message={alert || ""}
        onClose={() => setAlert(null)}
      />
      <SWRConfig
        value={{
          onError: async (err) => {
            const e = err as AxiosError;
            if (e.isAxiosError) {
              if (e.response?.status === 401) {
                AuthManager.logout();
                await router.push("/login?status=please+login");
                return;
              }
              if (e.response?.status === 404) {
                // not setting an alert for 404s
                // globally. the should be handled at the point
                // in which the 404 is called
                return;
              }
              setAlert(e.message);
            } else {
              setAlert("An unexpected error occurred");
            }
          },
        }}
      >
        {children}
      </SWRConfig>
    </>
  );
};

export default GlobalLayout;
