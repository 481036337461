import * as React from "react";
import { SVGProps } from "react";

const Tools = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.875 2.95a.125.125 0 0 0-.032.017 7.24 7.24 0 0 0-3.093 5.94 7.247 7.247 0 0 0 3.654 6.298c.57.326.982.954.941 1.681v.002l-.317 6.058a.75.75 0 0 1-1.498-.078l.317-6.062v-.003c.006-.09-.047-.216-.188-.297A8.747 8.747 0 0 1 3.25 8.907 8.74 8.74 0 0 1 6.982 1.74a1.547 1.547 0 0 1 1.709-.065c.484.292.809.835.809 1.46v4.714a.25.25 0 0 0 .119.213l2.25 1.385c.08.05.182.05.262 0l2.25-1.385a.25.25 0 0 0 .119-.213V3.135c0-.626.325-1.169.81-1.46a1.547 1.547 0 0 1 1.708.064 8.74 8.74 0 0 1 3.732 7.168 8.747 8.747 0 0 1-4.41 7.6c-.14.08-.193.205-.188.296v.003l.318 6.062a.75.75 0 0 1-1.498.079l-.317-6.059v-.002c-.041-.727.37-1.355.94-1.681a7.247 7.247 0 0 0 3.655-6.298 7.24 7.24 0 0 0-3.093-5.94.125.125 0 0 0-.032-.017l-.01-.002c-.003 0-.014 0-.031.01-.036.023-.084.08-.084.177v4.713a1.75 1.75 0 0 1-.833 1.49l-2.25 1.386a1.75 1.75 0 0 1-1.834 0l-2.25-1.385A1.75 1.75 0 0 1 8 7.849V3.134c0-.098-.048-.154-.084-.176a.062.062 0 0 0-.031-.011l-.01.002Z"
    />
  </svg>
);

export default Tools;
