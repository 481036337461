import * as React from "react";
import { SVGProps } from "react";

const CommentDiscussion = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75 1.657A1.75 1.75 0 0 0 0 3.407v9.5c0 .966.784 1.75 1.75 1.75H3V16.2a1.457 1.457 0 0 0 2.487 1.03l2.574-2.573h6.189a1.75 1.75 0 0 0 1.75-1.75v-9.5a1.75 1.75 0 0 0-1.75-1.75H1.75Zm-.25 1.75a.25.25 0 0 1 .25-.25h12.5a.25.25 0 0 1 .25.25v9.5a.25.25 0 0 1-.25.25h-6.5a.75.75 0 0 0-.53.22l-2.72 2.72v-2.19a.75.75 0 0 0-.75-.75h-2a.25.25 0 0 1-.25-.25v-9.5Z"
    />
    <path d="M22.5 9.407a.25.25 0 0 0-.25-.25h-3.5a.75.75 0 0 1 0-1.5h3.5c.966 0 1.75.783 1.75 1.75v9.5a1.75 1.75 0 0 1-1.75 1.75H21V22.2a1.457 1.457 0 0 1-2.487 1.03l-2.574-2.573H10.75A1.75 1.75 0 0 1 9 18.907v-1.465a.75.75 0 0 1 1.5 0v1.465c0 .138.112.25.25.25h5.5a.75.75 0 0 1 .53.22l2.72 2.72v-2.19a.75.75 0 0 1 .75-.75h2a.25.25 0 0 0 .25-.25v-9.5Z" />
  </svg>
);

export default CommentDiscussion;
