import * as React from "react";
import { SVGProps } from "react";

const DesktopDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M11.25 9.989V1.407a.75.75 0 0 1 1.5 0v8.582l1.949-2.112A.75.75 0 1 1 15.8 8.895l-3.25 3.52a.75.75 0 0 1-1.102 0l-3.25-3.52A.75.75 0 1 1 9.3 7.877L11.25 9.99Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 4.407a.25.25 0 0 1 .25-.25h5.5a.75.75 0 1 0 0-1.5h-5.5A1.75 1.75 0 0 0 1 4.407v11.5c0 .967.784 1.75 1.75 1.75h6.204c-.171 1.375-.805 2.652-1.77 3.757a.75.75 0 0 0 .566 1.243h8.5a.75.75 0 0 0 .565-1.243c-.964-1.105-1.598-2.382-1.769-3.757h6.204a1.75 1.75 0 0 0 1.75-1.75v-11.5a1.75 1.75 0 0 0-1.75-1.75h-5.5a.75.75 0 0 0 0 1.5h5.5a.25.25 0 0 1 .25.25v11.5a.25.25 0 0 1-.25.25H2.75a.25.25 0 0 1-.25-.25v-11.5Zm7.963 13.25c-.126 1.266-.564 2.445-1.223 3.5h5.52c-.66-1.055-1.098-2.234-1.223-3.5h-3.074Z"
    />
  </svg>
);

export default DesktopDownload;
