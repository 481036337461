import * as React from "react";
import { SVGProps } from "react";

const EyeSlash = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.052 6.494A9.715 9.715 0 0 1 12 5.657c2.956 0 5.31 1.316 7.06 2.865 1.756 1.552 2.866 3.306 3.308 4.08a.108.108 0 0 1-.002.114 16.756 16.756 0 0 1-1.53 2.218.75.75 0 0 0 1.163.947 18.266 18.266 0 0 0 1.67-2.421 1.608 1.608 0 0 0 .002-1.602c-.485-.85-1.691-2.757-3.617-4.46-1.93-1.707-4.622-3.24-8.054-3.24-1.695 0-3.215.375-4.552.963a.75.75 0 1 0 .604 1.373Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.166 18.645c-1.838 1.391-4.233 2.512-7.166 2.512-3.432 0-6.124-1.534-8.054-3.24-1.925-1.703-3.131-3.61-3.616-4.46a1.606 1.606 0 0 1 .002-1.601 18.305 18.305 0 0 1 3.316-4.188L1.318 6.02a.75.75 0 1 1 .865-1.225l20.5 14.5a.75.75 0 1 1-.866 1.224l-2.65-1.874ZM4.902 8.555c-1.73 1.542-2.827 3.273-3.268 4.044a.118.118 0 0 0-.017.059c0 .016.004.034.016.055.441.774 1.551 2.528 3.307 4.08 1.751 1.55 4.105 2.864 7.06 2.864 2.334 0 4.291-.82 5.874-1.927l-3.516-2.486a3.5 3.5 0 0 1-5.583-3.949l-3.873-2.74Z"
    />
  </svg>
);

export default EyeSlash;
