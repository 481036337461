import * as React from "react";
import { SVGProps } from "react";

const Unverifed = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13 17.157a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM10.483 9.492c.112-.223.268-.424.488-.57.215-.144.535-.265 1.029-.265.384 0 .766.118 1.034.319a.953.953 0 0 1 .403.806c0 .48-.218.81-.62 1.186a9.361 9.361 0 0 1-.409.354 19.8 19.8 0 0 0-.294.249c-.246.213-.524.474-.738.795l-.126.19v1.601a.75.75 0 0 0 1.5 0v-1.12c.09-.1.203-.208.347-.333.063-.055.14-.119.222-.187.166-.14.358-.3.52-.452.536-.5 1.098-1.2 1.098-2.283a2.45 2.45 0 0 0-1.003-2.006c-.565-.424-1.276-.619-1.934-.619-.756 0-1.373.191-1.861.517a2.944 2.944 0 0 0-.997 1.148.75.75 0 0 0 1.341.67Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.864 1.857a3.61 3.61 0 0 1 4.272 0l1.375 1.01c.274.2.593.333.929.384l1.686.259a3.61 3.61 0 0 1 3.021 3.02l.259 1.687c.051.336.183.655.384.929l1.01 1.375a3.61 3.61 0 0 1 0 4.272l-1.01 1.375a2.11 2.11 0 0 0-.384.929l-.259 1.686a3.61 3.61 0 0 1-3.02 3.021l-1.687.259a2.11 2.11 0 0 0-.929.384l-1.375 1.01a3.61 3.61 0 0 1-4.272 0l-1.375-1.01a2.11 2.11 0 0 0-.929-.384l-1.686-.259a3.61 3.61 0 0 1-3.021-3.02l-.259-1.687a2.11 2.11 0 0 0-.384-.929L1.2 14.793a3.61 3.61 0 0 1 0-4.272l1.01-1.375a2.11 2.11 0 0 0 .384-.929l.259-1.686a3.61 3.61 0 0 1 3.02-3.021L7.56 3.25a2.11 2.11 0 0 0 .929-.384l1.375-1.01Zm3.384 1.209a2.11 2.11 0 0 0-2.496 0l-1.376 1.01a3.61 3.61 0 0 1-1.589.658l-1.686.258a2.11 2.11 0 0 0-1.766 1.766l-.258 1.686a3.61 3.61 0 0 1-.658 1.59l-1.01 1.375a2.11 2.11 0 0 0 0 2.496l1.01 1.376a3.61 3.61 0 0 1 .658 1.589l.258 1.686a2.11 2.11 0 0 0 1.766 1.765l1.686.26a3.61 3.61 0 0 1 1.59.657l1.375 1.01a2.11 2.11 0 0 0 2.496 0l1.376-1.01a3.609 3.609 0 0 1 1.589-.658l1.686-.258a2.11 2.11 0 0 0 1.765-1.766l.26-1.686a3.61 3.61 0 0 1 .657-1.59l1.01-1.375a2.11 2.11 0 0 0 0-2.496l-1.01-1.376a3.61 3.61 0 0 1-.658-1.589l-.258-1.686a2.11 2.11 0 0 0-1.766-1.766l-1.686-.258a3.61 3.61 0 0 1-1.59-.658l-1.375-1.01Z"
    />
  </svg>
);

export default Unverifed;
