// @ts-nocheck

import { useEffect } from "react";
import getConfig from "next/config";

const useIntercom = () => {
  const { publicRuntimeConfig } = getConfig();
  const INTERCOM_APP_ID = publicRuntimeConfig.INTERCOM_APP_ID;
  useEffect(() => {
    if (!INTERCOM_APP_ID) {
      console.error("No intercom ID");
      return;
    }
    // Copy pasted from: https://developers.intercom.com/installing-intercom/web/installation/
    // Initialize intercom
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/" + INTERCOM_APP_ID;
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }

    // Start the intercom
    window.Intercom("boot", {
      app_id: INTERCOM_APP_ID,
    });
    console.info("Intercom initialized");
  }, [INTERCOM_APP_ID]);
};

export default useIntercom;
