import * as React from "react";
import { SVGProps } from "react";

const Thumbsup = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.596 2.7c-1.301-.092-2.303.986-2.303 2.207v1.052c0 2.667-1.813 3.785-2.774 4.2a1.867 1.867 0 0 1-.523.131A1.75 1.75 0 0 0 5.25 8.657h-1.5A1.75 1.75 0 0 0 2 10.407v10.5c0 .967.784 1.75 1.75 1.75h1.5a1.75 1.75 0 0 0 1.742-1.58c.838.061 1.667.296 2.69.586l.602.17c1.464.406 3.213.824 5.544.824 2.188 0 3.693-.204 4.583-1.372.422-.554.65-1.255.816-2.05.148-.708.262-1.57.396-2.58l.051-.39c.319-2.386.328-4.18-.223-5.393-.293-.645-.743-1.126-1.355-1.432-.59-.296-1.284-.403-2.036-.403h-2.05l.056-.43c.025-.18.05-.372.076-.572.06-.483.117-1.006.117-1.438 0-1.244-.222-2.253-.92-2.941-.684-.675-1.668-.88-2.743-.956ZM7 19.575c1.059.064 2.079.355 3.118.652l.568.16c1.406.39 3.006.77 5.142.77 2.277 0 3.004-.274 3.39-.78.216-.284.388-.719.54-1.45.136-.648.242-1.45.379-2.475l.05-.385c.32-2.4.253-3.794-.102-4.575-.16-.352-.375-.568-.66-.71-.305-.153-.74-.245-1.365-.245h-2.37c-.681 0-1.293-.571-1.211-1.329.026-.243.065-.537.105-.834l.07-.527c.06-.481.105-.921.105-1.25 0-1.125-.213-1.617-.473-1.873-.275-.27-.774-.455-1.795-.527-.351-.025-.698.273-.698.71v1.052c0 3.55-2.488 5.063-3.68 5.577-.372.16-.754.232-1.113.26v7.78Zm-3.25 1.582a.25.25 0 0 1-.25-.25v-10.5a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v10.5a.25.25 0 0 1-.25.25h-1.5Z"
    />
  </svg>
);

export default Thumbsup;
