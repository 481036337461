import Icon from "components/icon/icon";
import React, { FC } from "react";

type Props = {
  visible?: boolean;
  message?: string;
  className?: string;
  onClose?: () => void;
};

const Alert: FC<Props> = ({ visible = false, message = "", className, onClose = () => {} }) => (
  <>
    {visible ? (
      <div
        role="alert"
        onClick={onClose}
        className={`alert alert-error z-20 text-white p-4 my-2 rounded-2xl flex flex-row space-x-2 items-center shadow-main ${className} w-fit`}
      >
        <Icon kind="Report" width={24} className="fill-white text-white" />
        <p className="text-subtitle font-thin">{message}</p>
      </div>
    ) : null}
  </>
);

export default Alert;
