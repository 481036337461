import * as React from "react";
import { SVGProps } from "react";

const Plug = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 12.157v3.848a1.75 1.75 0 0 0 1.57 1.741l6.055.627 1.006 1.173a1.75 1.75 0 0 0 1.329.611h1.29a1.75 1.75 0 0 0 1.75-1.75v-2.25h3.25a.75.75 0 0 0 0-1.5H20v-6.5h3.25a.75.75 0 0 0 0-1.5H20v-2.25a1.75 1.75 0 0 0-1.75-1.75h-1.29c-.51 0-.996.223-1.329.611l-1.006 1.174-6.055.626A1.75 1.75 0 0 0 7 6.81v3.848H2.937A2.938 2.938 0 0 0 0 13.595v8.312a.75.75 0 0 0 1.5 0v-8.312c0-.794.644-1.438 1.438-1.438H7Zm9.77-7.912a.25.25 0 0 1 .19-.088h1.29a.25.25 0 0 1 .25.25v14a.25.25 0 0 1-.25.25h-1.29a.25.25 0 0 1-.19-.087l-1.2-1.4a.75.75 0 0 0-.493-.259l-6.353-.657a.25.25 0 0 1-.224-.249V6.81a.25.25 0 0 1 .224-.249l6.353-.657a.75.75 0 0 0 .492-.258l1.201-1.4Z"
    />
  </svg>
);

export default Plug;
