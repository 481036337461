import * as React from "react";
import { SVGProps } from "react";

const Law = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 3.407a.75.75 0 0 0-1.5 0v1.75H9.276a1.75 1.75 0 0 0-.985.303L6.595 6.614a.25.25 0 0 1-.14.043H2.352a.75.75 0 1 0 0 1.5h1.579l-3.368 7.68a.762.762 0 0 0 .21.88c.08.065.16.125.309.222.185.12.452.277.792.433.68.31 1.662.619 2.876.619a6.919 6.919 0 0 0 2.875-.62c.34-.155.607-.311.793-.432a3.5 3.5 0 0 0 .31-.224.75.75 0 0 0 .209-.878l-3.368-7.68h.886c.35 0 .694-.105.984-.303L9.135 6.7a.25.25 0 0 1 .14-.043h1.975v14.5H6.763a.75.75 0 0 0 0 1.5h10.474a.75.75 0 0 0 0-1.5H12.75v-14.5h1.974c.05 0 .1.015.14.043l1.697 1.154c.29.198.633.303.984.303h.886l-3.368 7.68a.75.75 0 0 0 .23.896c.012.009 0 0 .002.001a3.154 3.154 0 0 0 .31.206c.185.111.45.256.79.399a7.345 7.345 0 0 0 2.855.568 7.346 7.346 0 0 0 2.855-.568 6.24 6.24 0 0 0 .79-.4c.15-.089.23-.145.311-.206a.75.75 0 0 0 .23-.896l-3.367-7.68h1.578a.75.75 0 0 0 0-1.5h-4.102a.25.25 0 0 1-.14-.043L15.707 5.46a1.75 1.75 0 0 0-.984-.303H12.75v-1.75ZM2.193 15.855a5.42 5.42 0 0 0 2.557.635 5.42 5.42 0 0 0 2.557-.635l-2.557-5.83-2.557 5.83Zm14.51-.024c.082.041.174.083.274.126.53.223 1.306.45 2.273.45a5.846 5.846 0 0 0 2.546-.575l-2.546-5.807-2.547 5.806Z"
    />
  </svg>
);

export default Law;
