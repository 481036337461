import * as React from "react";
import { SVGProps } from "react";

const Link = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.78 4.31a3.936 3.936 0 1 1 5.567 5.567l-3.627 3.627a3.936 3.936 0 0 1-5.88-.353.75.75 0 0 0-1.18.928 5.436 5.436 0 0 0 8.12.486l3.628-3.628A5.436 5.436 0 1 0 13.72 3.25l-3 3a.75.75 0 0 0 1.06 1.06l3-3Z" />
    <path d="M7.28 11.81a3.936 3.936 0 0 1 5.88.353.75.75 0 1 0 1.18-.928 5.436 5.436 0 0 0-8.12-.486l-3.628 3.628a5.436 5.436 0 1 0 7.688 7.688l3-3a.75.75 0 1 0-1.06-1.06l-3 3a3.936 3.936 0 0 1-5.567-5.568L7.28 11.81Z" />
  </svg>
);

export default Link;
