import * as React from "react";
import { SVGProps } from "react";

const NotydLetter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" {...props}>
    <path
      d="m62.45 112.07 2.99-1.08a2.957 2.957 0 0 0 1.96-2.79V71.77s.04-.05.06-.02l36.92 39.55c.81.87 2.06 1.17 3.18.77l2.99-1.08a2.957 2.957 0 0 0 1.96-2.79V63.76c0-1.33-.5-2.61-1.41-3.58L90.76 38.43a2.977 2.977 0 0 0-3.17-.77l-3.01 1.08a2.966 2.966 0 0 0-1.97 2.79v36.43s-.04.05-.06.02L45.63 38.43a2.963 2.963 0 0 0-3.17-.77l-2.98 1.07a2.957 2.957 0 0 0-1.96 2.79v44.43c0 1.33.51 2.61 1.41 3.58l20.36 21.75a2.94 2.94 0 0 0 3.17.76Zm26.98-65.2 16.26 17.45v38.52L89.43 85.39V46.87Zm-45.1.04 16.26 17.45v38.52L44.33 85.43V46.91Z"
      style={{
        fill: "#23c09c",
        strokeWidth: 0,
      }}
    />
    <path
      style={{
        strokeWidth: 0,
        fill: "none",
      }}
      d="M0 0h150v150H0z"
    />
  </svg>
);
export default NotydLetter;
