
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import "styles/globals.css";
import type { AppProps } from "next/app";
import { NextPage } from "next";

import type { ReactElement, ReactNode } from "react";
import { useEffect } from "react";
import GlobalLayout from "components/layout/global-layout";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import getConfig from "next/config";
import useIntercom from "api/hooks/useIntercom";

export type NextPageWithLayout<P> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout<P> = AppProps & {
  Component: NextPageWithLayout<P>;
};

const NotydSellerPortal: NextPage<AppPropsWithLayout<{}>, {}> = ({ Component, pageProps }) => {
  const { publicRuntimeConfig } = getConfig();
  const gtmId = publicRuntimeConfig.GTM_ID;
  const tagManagerArgs: TagManagerArgs = {
    gtmId: gtmId,
  };

  useIntercom();

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  return <GlobalLayout>{getLayout(<Component {...pageProps} />)}</GlobalLayout>;
};

const __Page_Next_Translate__ = NotydSellerPortal;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  