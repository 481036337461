import * as React from "react";
import { SVGProps } from "react";

const Paintbrush = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.351 5.246c-1.812 1.31-3.86 3.25-5.574 4.945l-.092.09 2.689 2.69.098-.097c1.71-1.69 3.657-3.732 4.963-5.544.657-.912 1.114-1.715 1.317-2.353.208-.652.092-.915-.032-1.04h-.001c-.113-.113-.366-.23-1.019-.018-.635.207-1.437.668-2.349 1.327Zm-4.082 8.74-2.606-2.604c-.754.879-1.293 1.684-1.655 2.378a4.837 4.837 0 0 1 1.89 1.892c.69-.36 1.483-.903 2.37-1.665Zm-1.856 3.087a4.825 4.825 0 0 1-1.328 4.33c-.526.525-1.303.88-2.08 1.13-.795.256-1.681.43-2.506.54a19.05 19.05 0 0 1-2.216.173c-.3.005-.568-.002-.779-.02a2.47 2.47 0 0 1-.31-.045 1.195 1.195 0 0 1-.179-.053.857.857 0 0 1-.295-.19l-.001-.001a.857.857 0 0 1-.19-.296 1.205 1.205 0 0 1-.053-.178 2.485 2.485 0 0 1-.044-.31 8.009 8.009 0 0 1-.022-.78c.008-.602.061-1.388.173-2.215.111-.825.285-1.712.54-2.507.25-.777.606-1.554 1.131-2.08a4.825 4.825 0 0 1 4.333-1.326c.6-1.21 1.618-2.622 3.135-4.121 1.714-1.694 3.835-3.71 5.75-5.094.953-.688 1.905-1.258 2.764-1.537.843-.275 1.827-.334 2.544.384.72.719.672 1.703.402 2.554-.275.866-.843 1.822-1.53 2.776-1.385 1.92-3.408 4.036-5.125 5.734-1.518 1.5-2.897 2.53-4.114 3.132Zm-2.389-1.44a3.33 3.33 0 0 0-4.709 0c-.271.27-.537.774-.764 1.477-.22.684-.377 1.478-.482 2.249a17.559 17.559 0 0 0-.159 2.035c-.002.131 0 .249.002.351.102.003.22.003.35.002.541-.008 1.267-.056 2.036-.16.77-.104 1.564-.262 2.248-.482.704-.226 1.207-.492 1.478-.763a3.33 3.33 0 0 0 0-4.71Z"
    />
  </svg>
);

export default Paintbrush;
