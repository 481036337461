import * as React from "react";
import { SVGProps } from "react";

const Lightbulb = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.85 1.407C6.901 1.407 4 5.148 4 9.257c0 2.51 1.193 4.206 2.264 5.569l.336.423c.411.516.773.97 1.061 1.439.353.573.547 1.098.547 1.658v1.171a3.64 3.64 0 0 0 3.642 3.64 3.64 3.64 0 0 0 3.643-3.64v-1.171c0-.56.194-1.084.546-1.657.289-.47.65-.923 1.061-1.439.109-.136.221-.277.336-.424 1.072-1.363 2.265-3.06 2.265-5.569 0-4.109-2.902-7.85-7.85-7.85ZM5.5 9.257c0-3.385 2.33-6.35 6.35-6.35s6.35 2.965 6.35 6.35c0 1.976-.91 3.33-1.943 4.642l-.3.379c-.419.524-.854 1.07-1.196 1.625a5.51 5.51 0 0 0-.585 1.254H9.525a5.509 5.509 0 0 0-.586-1.255c-.341-.555-.777-1.101-1.195-1.625a63.382 63.382 0 0 1-.3-.378C6.41 12.586 5.5 11.233 5.5 9.257Zm4.208 9.4v.86a2.14 2.14 0 0 0 2.142 2.14 2.14 2.14 0 0 0 2.143-2.14v-.86H9.708Z"
    />
  </svg>
);

export default Lightbulb;
