import * as React from "react";
import { SVGProps } from "react";

const Thumbsdown = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.596 22.614c-1.301.092-2.303-.985-2.303-2.206v-1.053c0-2.666-1.813-3.785-2.774-4.2a1.864 1.864 0 0 0-.523-.13 1.75 1.75 0 0 1-1.746 1.632h-1.5A1.75 1.75 0 0 1 2 14.907v-10.5c0-.966.784-1.75 1.75-1.75h1.5a1.75 1.75 0 0 1 1.742 1.58c.838-.06 1.667-.296 2.69-.586l.602-.169c1.464-.407 3.213-.825 5.544-.825 2.188 0 3.693.204 4.583 1.372.422.554.65 1.256.816 2.05.148.708.262 1.57.396 2.58l.051.39c.319 2.386.328 4.18-.223 5.394-.293.644-.743 1.126-1.355 1.432-.59.296-1.284.403-2.036.403h-2.05l.056.429.076.573c.06.482.117 1.005.117 1.438 0 1.244-.222 2.252-.92 2.94-.684.675-1.668.88-2.743.956ZM7 5.74c1.059-.064 2.079-.355 3.118-.651.188-.054.377-.108.568-.16 1.406-.391 3.006-.77 5.142-.77 2.277 0 3.004.274 3.39.78.216.284.388.718.54 1.449.136.649.242 1.45.379 2.476l.05.385c.32 2.399.253 3.794-.102 4.574-.16.352-.375.569-.66.711-.305.153-.74.245-1.365.245h-2.37c-.681 0-1.293.57-1.211 1.328.026.244.065.537.105.834l.07.527c.06.482.105.922.105 1.25 0 1.125-.213 1.617-.473 1.873-.275.271-.774.456-1.795.528-.351.025-.698-.274-.698-.71v-1.053c0-3.55-2.488-5.063-3.68-5.577A3.485 3.485 0 0 0 7 13.518V5.739ZM3.75 4.157a.25.25 0 0 0-.25.25v10.5c0 .138.112.25.25.25h1.5a.25.25 0 0 0 .25-.25v-10.5a.25.25 0 0 0-.25-.25h-1.5Z"
    />
  </svg>
);

export default Thumbsdown;
