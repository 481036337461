import * as React from "react";
import { SVGProps } from "react";

const Mirror = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11.407a.75.75 0 0 1 .75.75v1a.75.75 0 0 1-1.5 0v-1a.75.75 0 0 1 .75-.75ZM12 15.407a.75.75 0 0 1 .75.75v1a.75.75 0 0 1-1.5 0v-1a.75.75 0 0 1 .75-.75ZM12 19.407a.75.75 0 0 1 .75.75v1a.75.75 0 0 1-1.5 0v-1a.75.75 0 0 1 .75-.75ZM12 7.407a.75.75 0 0 1 .75.75v1a.75.75 0 0 1-1.5 0v-1a.75.75 0 0 1 .75-.75ZM12 3.407a.75.75 0 0 1 .75.75v1a.75.75 0 0 1-1.5 0v-1a.75.75 0 0 1 .75-.75ZM21.553 6.72a.75.75 0 0 1 .447.687v10.5a.75.75 0 0 1-1.256.554l-5.75-5.25a.75.75 0 0 1 0-1.108l5.75-5.25a.75.75 0 0 1 .809-.132Zm-4.94 5.937 3.887 3.55v-7.1l-3.888 3.55ZM2.447 18.593A.75.75 0 0 1 2 17.907v-10.5a.75.75 0 0 1 1.256-.554l5.75 5.25a.75.75 0 0 1 0 1.108l-5.75 5.25a.75.75 0 0 1-.809.132Zm4.94-5.936L3.5 9.107v7.1l3.888-3.55Z"
    />
  </svg>
);

export default Mirror;
