import * as React from "react";
import { SVGProps } from "react";

const Trash = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2.407v1.25h5.25a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1 0-1.5H8v-1.25c0-.966.784-1.75 1.75-1.75h4.5c.966 0 1.75.784 1.75 1.75Zm-6.5 0a.25.25 0 0 1 .25-.25h4.5a.25.25 0 0 1 .25.25v1.25h-5v-1.25Z"
    />
    <path d="M4.997 6.835a.75.75 0 0 0-1.493.144l1.412 14.597a1.75 1.75 0 0 0 1.742 1.581h10.684a1.75 1.75 0 0 0 1.742-1.581l1.413-14.597a.75.75 0 0 0-1.494-.144l-1.412 14.596a.25.25 0 0 1-.249.226H6.658a.25.25 0 0 1-.249-.226L4.997 6.835Z" />
    <path d="M9.206 8.159a.75.75 0 0 1 .793.704l.5 8.5A.75.75 0 1 1 9 17.451l-.5-8.5a.75.75 0 0 1 .705-.792ZM15.499 8.951A.75.75 0 1 0 14 8.863l-.5 8.5a.75.75 0 0 0 1.498.088l.5-8.5Z" />
  </svg>
);

export default Trash;
