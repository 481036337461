import * as React from "react";
import { SVGProps } from "react";

const Infinity = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 9.157c-2.032 0-3.75 1.895-3.75 3.75s1.718 3.75 3.75 3.75c1.017 0 2.014-.457 3.062-1.253.89-.678 1.758-1.554 2.655-2.497-.897-.943-1.765-1.82-2.655-2.497-1.048-.796-2.045-1.253-3.062-1.253Zm6.75 2.66c-.887-.933-1.813-1.865-2.78-2.6-1.172-.892-2.487-1.56-3.97-1.56-2.907 0-5.25 2.615-5.25 5.25s2.343 5.25 5.25 5.25c1.483 0 2.798-.669 3.97-1.56.967-.735 1.893-1.668 2.78-2.6.887.933 1.813 1.865 2.78 2.6 1.172.891 2.487 1.56 3.97 1.56 2.907 0 5.25-2.615 5.25-5.25s-2.343-5.25-5.25-5.25c-1.483 0-2.798.668-3.97 1.56-.967.735-1.893 1.667-2.78 2.6Zm1.033 1.09c.897.943 1.765 1.82 2.655 2.497 1.048.796 2.045 1.253 3.062 1.253 2.032 0 3.75-1.895 3.75-3.75s-1.718-3.75-3.75-3.75c-1.017 0-2.014.457-3.062 1.253-.89.678-1.758 1.554-2.655 2.497Z"
    />
  </svg>
);

export default Infinity;
