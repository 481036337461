import * as React from "react";
import { SVGProps } from "react";

const MortarBoard = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.292 2.716a.75.75 0 0 0-.584 0L.458 7.466a.75.75 0 0 0 0 1.382L4.25 10.45v3.804a2.9 2.9 0 0 0-1.327.757C2.344 15.588 2 16.42 2 17.438v4.5c0 .25.128.487.335.625.06.04.117.073.22.124.124.062.297.139.52.213.448.149 1.09.289 1.925.289s1.477-.14 1.925-.289c.223-.074.396-.15.52-.212.097-.049.153-.082.21-.118A.762.762 0 0 0 8 21.939v-4.5c0-1.019-.344-1.85-.923-2.43a2.9 2.9 0 0 0-1.327-.756v-3.17l5.958 2.515a.75.75 0 0 0 .584 0L17.5 11.4v4.002a4.057 4.057 0 0 1-.928.735c-.826.488-2.255 1.021-4.572 1.021-.612 0-1.162-.037-1.654-.1a.75.75 0 0 0-.192 1.487c.56.072 1.173.113 1.846.113 2.558 0 4.254-.591 5.334-1.229.538-.317.914-.64 1.163-.896.143-.148.284-.306.392-.483h.001a.75.75 0 0 0 .11-.392v-4.891l4.542-1.918a.75.75 0 0 0 0-1.382l-11.25-4.75ZM5 15.657c-.377 0-.745.141-1.017.413-.265.265-.483.7-.483 1.368v4.022c.299.105.797.229 1.5.229s1.201-.124 1.5-.229v-4.021c0-.67-.218-1.104-.483-1.369A1.432 1.432 0 0 0 5 15.657Zm7-3.564L2.678 8.157 12 4.221l9.322 3.936L12 12.093Z"
    />
  </svg>
);

export default MortarBoard;
