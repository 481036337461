import * as React from "react";
import { SVGProps } from "react";

const Organization = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.25 12.657a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5ZM6.5 9.907a.75.75 0 0 1 .75-.75h.5a.75.75 0 1 1 0 1.5h-.5a.75.75 0 0 1-.75-.75ZM7.25 5.657a.75.75 0 1 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5ZM10 13.407a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75ZM10.75 9.157a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5ZM10 6.407a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75ZM14.25 12.657a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5ZM13.5 9.907a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75ZM14.25 5.657a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 20.657a2 2 0 0 0 2 2h3.75a.75.75 0 0 0 .75-.75v-2.25h3v2.25c0 .414.336.75.75.75H17c.092 0 .183-.006.272-.018a.752.752 0 0 0 .166.018H21.5a2 2 0 0 0 2-2v-7.625a2 2 0 0 0-.8-1.6l-1-.75a.75.75 0 1 0-.9 1.2l1 .75a.5.5 0 0 1 .2.4v7.625a.5.5 0 0 1-.5.5h-2.563c.041-.16.063-.327.063-.5v-17a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v17Zm2 .5a.5.5 0 0 1-.5-.5v-17a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 .5.5v17a.5.5 0 0 1-.5.5h-3v-2.25a.75.75 0 0 0-.75-.75h-4.5a.75.75 0 0 0-.75.75v2.25H5Z"
    />
  </svg>
);

export default Organization;
