import * as React from "react";
import { SVGProps } from "react";

const Telescope = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.408 15.787a2 2 0 0 1 .59-2.642l16.04-11.159a2 2 0 0 1 2.851.602l2.827 4.645a2 2 0 0 1-.85 2.846L4.102 18.51a2 2 0 0 1-2.59-.807L.408 15.787Zm5.264-4.066 7.841-5.455 2.857 4.76-8.712 4.135-1.986-3.44Zm-1.236.86-2.582 1.796a.5.5 0 0 0-.147.66l1.105 1.915a.5.5 0 0 0 .648.202l2.838-1.347-1.862-3.226Zm13.296-2.2-2.984-4.974 3.147-2.19a.5.5 0 0 1 .713.151l2.827 4.645a.5.5 0 0 1-.213.711l-3.49 1.657Z"
    />
    <path d="M17.155 23.528a.75.75 0 0 0 .227-1.037l-4-6.238a.75.75 0 0 0-.942-.278l-2.75 1.25a.75.75 0 0 0-.318.273l-3.25 4.989a.75.75 0 1 0 1.257.819l3.13-4.806.51-.232v5.64a.75.75 0 0 0 1.5 0v-6.22l3.6 5.613a.75.75 0 0 0 1.036.227Z" />
  </svg>
);

export default Telescope;
