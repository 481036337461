import * as React from "react";
import { SVGProps } from "react";

const Globe = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.657c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11Zm-9.487 10.5h4.745c.1-3.037 1.1-5.49 2.093-7.204.39-.672.78-1.233 1.119-1.673-4.36.706-7.724 4.377-7.957 8.877Zm4.77 1.5H2.552a9.505 9.505 0 0 0 7.918 8.378A15.698 15.698 0 0 1 9.35 20.36c-.938-1.618-1.882-3.897-2.068-6.704Zm1.504 0h6.426c-.183 2.48-1.02 4.5-1.862 5.951-.476.82-.95 1.455-1.304 1.881l-.047.057-.047-.057a13.896 13.896 0 0 1-1.304-1.88c-.842-1.452-1.68-3.473-1.862-5.952Zm6.454-1.5H8.759c.1-2.708.992-4.904 1.89-6.451.476-.82.95-1.455 1.304-1.88L12 3.767l.047.057c.353.426.828 1.06 1.304 1.88.898 1.548 1.79 3.744 1.89 6.452Zm1.476 1.5c-.186 2.807-1.13 5.086-2.068 6.704-.39.672-.78 1.233-1.118 1.674a9.505 9.505 0 0 0 7.917-8.378h-4.731Zm4.77-1.5h-4.745c-.1-3.037-1.1-5.49-2.093-7.204-.39-.672-.78-1.233-1.119-1.673 4.36.706 7.724 4.377 7.957 8.877Z"
    />
  </svg>
);

export default Globe;
