import * as React from "react";
import { SVGProps } from "react";

const Smiley = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9.503 15.296a.75.75 0 0 0-1.047-.145.767.767 0 0 0-.146 1.055 2.655 2.655 0 0 0 .26.29c.157.158.388.363.694.567a4.88 4.88 0 0 0 2.736.813 4.88 4.88 0 0 0 2.736-.814c.306-.203.537-.408.695-.566.079-.079.14-.147.184-.198l.079-.097a.75.75 0 0 0-1.197-.905l-.001.002a1.807 1.807 0 0 1-.126.137c-.1.1-.255.24-.466.38a3.38 3.38 0 0 1-1.904.561 3.38 3.38 0 0 1-1.904-.562c-.21-.14-.366-.279-.466-.379a1.682 1.682 0 0 1-.127-.139ZM16 12.407a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM9 11.157a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.657c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11Zm0 1.781a9.219 9.219 0 1 0 0 18.438 9.219 9.219 0 0 0 0-18.438Z"
    />
  </svg>
);

export default Smiley;
