import * as React from "react";
import { SVGProps } from "react";

const Verified = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M17.03 10.437a.75.75 0 0 0-1.06-1.06l-5.47 5.47-2.47-2.47a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l6-6Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.136 1.857a3.61 3.61 0 0 0-4.272 0l-1.375 1.01a2.11 2.11 0 0 1-.929.384l-1.686.259a3.61 3.61 0 0 0-3.021 3.02l-.259 1.687a2.11 2.11 0 0 1-.384.929L1.2 10.52a3.61 3.61 0 0 0 0 4.272l1.01 1.375c.2.274.333.593.384.929l.259 1.686a3.61 3.61 0 0 0 3.02 3.021l1.687.259c.336.051.655.183.929.384l1.375 1.01a3.61 3.61 0 0 0 4.272 0l1.375-1.01a2.11 2.11 0 0 1 .929-.384l1.686-.259a3.61 3.61 0 0 0 3.021-3.02l.259-1.687a2.11 2.11 0 0 1 .384-.929l1.01-1.375a3.61 3.61 0 0 0 0-4.272l-1.01-1.375a2.11 2.11 0 0 1-.384-.929l-.259-1.686a3.61 3.61 0 0 0-3.02-3.021L16.44 3.25a2.11 2.11 0 0 1-.929-.384l-1.375-1.01Zm-3.384 1.209a2.11 2.11 0 0 1 2.496 0l1.376 1.01a3.61 3.61 0 0 0 1.589.658l1.686.258a2.11 2.11 0 0 1 1.765 1.766l.26 1.686a3.61 3.61 0 0 0 .657 1.59l1.01 1.375a2.11 2.11 0 0 1 0 2.496l-1.01 1.376a3.61 3.61 0 0 0-.658 1.589l-.258 1.686a2.11 2.11 0 0 1-1.766 1.765l-1.686.26a3.609 3.609 0 0 0-1.59.657l-1.375 1.01a2.11 2.11 0 0 1-2.496 0l-1.376-1.01a3.61 3.61 0 0 0-1.589-.658l-1.686-.258a2.11 2.11 0 0 1-1.766-1.766l-.258-1.686a3.61 3.61 0 0 0-.658-1.59l-1.01-1.375a2.11 2.11 0 0 1 0-2.496l1.01-1.376a3.61 3.61 0 0 0 .658-1.589l.258-1.686a2.11 2.11 0 0 1 1.766-1.766l1.686-.258a3.61 3.61 0 0 0 1.59-.658l1.375-1.01Z"
    />
  </svg>
);

export default Verified;
