import * as React from "react";
import { SVGProps } from "react";

const Fire = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.185 22.157c4.059 0 7.065-2.84 7.065-6.75 0-2.337-1.093-3.489-2.678-5.158l-.021-.022c-1.44-1.517-3.139-3.352-3.649-6.557-.661.389-1.38.986-1.911 1.759-.787 1.144-1.147 2.634-.216 4.495.603 1.205.777 2.74-.277 3.794-.657.657-1.762 1.1-2.956.586-.752-.323-1.353-.955-1.838-1.79-.567.707-.954 1.74-.954 2.893 0 3.847 3.288 6.75 7.435 6.75Zm2.08-19.873c-.017-.345-.296-.625-.632-.543-2.337.575-6.605 4.042-4.2 8.854.474.947.392 1.675.004 2.062-.64.64-1.874.684-2.875-1.815-.131-.326-.498-.509-.803-.334-1.547.888-2.509 2.861-2.509 4.9 0 4.828 4.122 8.25 8.935 8.25 4.812 0 8.565-3.438 8.565-8.25 0-2.94-1.466-4.482-3.006-6.103-1.61-1.694-3.479-3.476-3.479-7.021Z"
    />
  </svg>
);

export default Fire;
