import * as React from "react";
import { SVGProps } from "react";

const Sync = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.38 8.657a9.502 9.502 0 0 1 8.62-5.5c4.447 0 8.181 3.055 9.215 7.182a.75.75 0 1 0 1.456-.364C21.473 5.196 17.15 1.657 12 1.657a10.995 10.995 0 0 0-9.5 5.452V5.407a.75.75 0 0 0-1.5 0v3.75a1 1 0 0 0 1 1h3.75a.75.75 0 0 0 0-1.5H3.38ZM2.785 14.975a.75.75 0 0 0-1.455.364c1.197 4.779 5.52 8.318 10.67 8.318 4.052 0 7.592-2.191 9.5-5.451v1.701a.75.75 0 0 0 1.5 0v-3.75a1 1 0 0 0-1-1h-3.75a.75.75 0 0 0 0 1.5h2.37a9.502 9.502 0 0 1-8.62 5.5c-4.446 0-8.181-3.055-9.215-7.182Z" />
  </svg>
);

export default Sync;
