var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"GNHNp2eJ-fa9zXm0jtku_"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import getConfig from "next/config";
import { APP_VERSION } from "./util/version";

const { publicRuntimeConfig } = getConfig();

const SENTRY_DSN = publicRuntimeConfig.SENTRY_DSN;
const SENTRY_ENV = publicRuntimeConfig.SENTRY_ENV;

if (
  SENTRY_DSN &&
  SENTRY_ENV &&
  SENTRY_DSN !== "{{SENTRY_DSN}}" && // check that env vars are NOT set with
  SENTRY_ENV !== "{{SENTRY_ENV}}" // runtime placeholders
) {
  Sentry.init({
    dsn: SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: SENTRY_ENV,
    dist: APP_VERSION,
    release: "seller-portal-v" + APP_VERSION,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
